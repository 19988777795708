<template>
    <div class="home">

        <!-- preloader -->
        <Loader />
        <!-- header -->
        <Header />

        <section class="gap responsive-iframe-container" style="padding-bottom: 0; margin-bottom: 0; padding-top: 2%;">
            <div class="container">
                <div class="iframe-container">
                    <iframe
                        src="https://mawaqit.net/nl/w/moskee-salaam-emmeloord-8302cg-netherlands-1?showOnly5PrayerTimes=0"
                        frameborder="0" scrolling="no"></iframe>
                </div>
            </div>
        </section>

        <section style="padding-top: 2%;">
            <div class="container">
                <div class="heading">
                    <h2>Pijlers van de Islam</h2>
                </div>
                <div class="pillars">
                    <div class="pillars-name">
                        <figure><img src="/assets/img/pillars-name-1.png" alt="img"></figure>
                        <img src="/assets/img/Muslim_Prayer.jpg" alt="img">
                        <a>Shahada</a>
                        <span>( Geloof )</span>
                    </div>
                    <div class="pillars-name">
                        <figure><img src="/assets/img/pillars-name-2.png" alt="img"></figure>
                        <img src="https://winsfolio.net/html/ibadah/assets/img/pillars-2.jpg" alt="img">
                        <a>Salat</a>
                        <span>( Gebed )</span>
                    </div>
                    <div class="pillars-name">
                        <figure><img src="/assets/img/pillars-name-3.png" alt="img"></figure>
                        <img src="https://winsfolio.net/html/ibadah/assets/img/pillars-3.jpg" alt="img">
                        <a>Siyaam</a>
                        <span>( Vasten )</span>
                    </div>
                    <div class="pillars-name">
                        <figure><img src="/assets/img/pillars-name-4.png" alt="img"></figure>
                        <img src="https://winsfolio.net/html/ibadah/assets/img/pillars-4.jpg" alt="img">
                        <a>Zakat</a>
                        <span>( Aalmoezen )</span>
                    </div>
                    <div class="pillars-name">
                        <figure><img src="/assets/img/pillars-name-5.png" alt="img"></figure>
                        <img src="https://winsfolio.net/html/ibadah/assets/img/pillars-5.jpg" alt="img">
                        <a>Hadj</a>
                        <span>( Bedevaart )</span>
                    </div>
                </div>
            </div>
        </section>


        <section class="gap" style="padding-top: 5%;">
            <div class="container">
                <div class="join-our-community" style="background-image: url(assets/img/color-bg.jpg);">
                    <h2>Moskee</h2>
                    <p>De moskee is een heilige plek waar moslims samenkomen om gebeden te verrichten en islamitisch
                        onderwijs te volgen.</p>
                    <div class="join-our-img">
                        <img src="https://winsfolio.net/html/ibadah/assets/img/join-our-3.jpg" alt="img">
                        <img src="https://winsfolio.net/html/ibadah/assets/img/join-our-4.jpg" alt="img">
                        <img src="https://winsfolio.net/html/ibadah/assets/img/join-our-2.jpg" alt="img">
                        <img src="https://winsfolio.net/html/ibadah/assets/img/join-our-1.jpg" alt="img">
                    </div>
                </div>
            </div>
        </section>



        <section class="islamic-ayat">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="heading">
                            <p>Koranverzen Met Vertaling</p>
                            <h2>Islamitische ayat met vertaling</h2>
                        </div>
                        <div>
                            <Ayat />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />

    </div>
</template>

<script lang="ts">

import Header from '@/components/Header.vue';
import Loader from '@/components/Loader.vue';
import Footer from '@/components/Footer.vue';
import Ayat from '@/components/Ayat.vue';

import { defineComponent } from 'vue';

import { useToast } from "vue-toastification";

export default defineComponent({
    name: 'HomeView',
    components: {
        Header,
        Loader,
        Footer,
        Ayat
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Use it!
        toast("Automatically translating this page into English through the browser may incorrectly display Arabic verses. Please avoid using this feature or be cautious if doing so.", {
            timeout: 10000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
        });

        toast("Automatisch vertalen van deze pagina naar het Engels via de browser kan ervoor zorgen dat Arabische verzen verkeerd worden weergegeven. Gelieve deze functie niet te gebruiken of voorzichtig te zijn bij gebruik.", {
            timeout: 10000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
        });

        // These options will override the options defined in the "app.use" plugin registration for this specific toast

        // Make it available inside methods
        return { toast }
    },

    methods: {
        myMethod() {

            // Since you returned `toast` from setup(), you can access it now
            this.toast.info("I'm an info toast!");
        }
    }
});
</script>
<style scoped>
/* Container relative to which the iframe will size */
.iframe-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* Aspect ratio of 16:9 (9 / 16 = 0.5625) */
    margin-bottom: 0;
}

/* Absolute positioning and stretching to fit the container */
.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
