<template>
  <section class="gap" >
        <div class="container">
            <div class="join-our-community" style="background-image: url(assets/img/color-bg.jpg);">
              <div class="row" style="text-align: center;">
                
                    <div class="col-lg-6 col-md-6">
                        <div class="widget-title">
                            <h3>Informatie</h3>
                            <p>De Assalaam Moskee in Emmeloord, opgericht in 2012, is een centrum van vrede en gemeenschap. Ontworpen met een unieke geel-groene koepel, verwelkomt het iedereen voor gebed en verbinding. Bezoek ons op Espelerlaan 67B.</p>
                        </div>
                    </div>

                    <div style="text-align: left" class="col-lg-6 col-md-6">
                        <div class="Information widget-title">
                            <h3>Contact informatie</h3>
                            
                            <div class="contact-info">
                                <i>
                                    <svg fill="none" height="48" viewBox="0 0 48 48" width="48"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-rule="evenodd" fill="rgb(0,0,0)" fill-rule="evenodd">
                                            <path
                                                d="m9 10c-1.65685 0-3 1.3431-3 3v22c0 1.6569 1.34315 3 3 3h30c1.6569 0 3-1.3431 3-3v-17c0-.5523.4477-1 1-1s1 .4477 1 1v17c0 2.7614-2.2386 5-5 5h-30c-2.76142 0-5-2.2386-5-5v-22c0-2.7614 2.23858-5 5-5h22c.5523 0 1 .44772 1 1s-.4477 1-1 1z" />
                                            <path
                                                d="m13.2929 16.2929c.3905-.3905 1.0237-.3905 1.4142 0l8.5858 8.5858c.3905.3905 1.0237.3905 1.4142 0l8.5858-8.5858c.3905-.3905 1.0237-.3905 1.4142 0s.3905 1.0237 0 1.4142l-8.5858 8.5858c-1.1716 1.1716-3.071 1.1716-4.2426 0l-8.5858-8.5858c-.3905-.3905-.3905-1.0237 0-1.4142z" />
                                            <path
                                                d="m40 11c1.1046 0 2-.8954 2-2 0-1.10457-.8954-2-2-2s-2 .89543-2 2c0 1.1046.8954 2 2 2zm0 2c2.2091 0 4-1.7909 4-4 0-2.20914-1.7909-4-4-4s-4 1.79086-4 4c0 2.2091 1.7909 4 4 4z" />
                                        </g>
                                    </svg>
                                </i>
                                <div>
                                    <h5>E-mailadres:</h5>
                                    <a href="mailto:username@domain.com">info@assalaammoskee.nl</a>
                                </div>
                            </div>
                            <div class="contact-info">
                                <i>
                                    <svg id="LOCATION" height="512" viewBox="0 0 1800 1800" width="512"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <g>
                                                <path
                                                    d="m899.993 1556.267 441.512-441.511c8.202-7.819 26.127-26.384 26.893-27.184l.36-.383c110.946-118.997 172.046-274.141 172.046-436.851 0-353.342-287.463-640.805-640.803-640.805-353.342 0-640.805 287.463-640.805 640.805 0 162.714 61.1 317.857 172.038 436.851zm.008-1485.108c319.355 0 579.179 259.818 579.179 579.18 0 146.968-55.159 287.114-155.315 394.639-5.202 5.387-19.292 19.873-25.095 25.383l-398.764 398.739-424.049-424.315c-100.055-107.499-155.137-247.556-155.137-394.446 0-319.362 259.814-579.18 579.181-579.18z"
                                                    fill="rgb(0,0,0)" />
                                            </g>
                                            <g>
                                                <path
                                                    d="m998.745 225.279c110.577 0 325.781 120.91 325.781 342.553 0 17.018 13.789 30.812 30.812 30.812 17.014 0 30.812-13.794 30.812-30.812 0-115.37-50.989-222.331-143.563-301.184-73.464-62.566-169.175-102.994-243.842-102.994-17.014 0-30.812 13.794-30.812 30.813s13.798 30.812 30.812 30.812z"
                                                    fill="rgb(0,0,0)" />
                                            </g>
                                            <g>
                                                <path
                                                    d="m1286.716 1361.056c-24.003-9.809-49.854-18.548-77.134-26.264l-50.474 50.478c148.765 35.502 240.488 98.79 240.488 157.599 0 87.962-205.171 185.974-499.596 185.974-294.417 0-499.597-98.012-499.597-185.974 0-58.805 91.723-122.097 240.488-157.599l-50.478-50.478c-27.271 7.716-53.126 16.455-77.121 26.264-112.537 45.995-174.513 110.563-174.513 181.813s61.977 135.817 174.513 181.813c103.793 42.422 241.128 65.785 386.708 65.785 145.582 0 282.921-23.363 386.715-65.785 112.536-45.995 174.504-110.563 174.504-181.813s-61.967-135.818-174.503-181.813z"
                                                    fill="rgb(0,0,0)" />
                                            </g>
                                            <g>
                                                <path
                                                    d="m901.771 945.221c-171.172 0-310.434-139.256-310.434-310.425s139.262-310.426 310.434-310.426 310.434 139.256 310.434 310.425-139.262 310.426-310.434 310.426zm0-559.226c-137.193 0-248.809 111.612-248.809 248.801s111.616 248.801 248.809 248.801c137.192 0 248.809-111.612 248.809-248.801s-111.616-248.801-248.809-248.801z"
                                                    fill="rgb(0,0,0)" />
                                            </g>
                                        </g>
                                    </svg>
                                </i>
                                <h5>Espelerlaan 67b 8302CG, EMMELOORD Netherlands</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
