<template>
  <header class="header" :style="`background-image: url(${backgroundImageUrl});`">
    <div class="container">
      <div class="header-content">
        <!-- Mobile Navigation Toggle -->
        <button class="mobile-nav-toggle" @click="toggleNav" :aria-expanded="navOpen.toString()"
          aria-controls="primary-navigation">
          <span class="sr-only">Menu</span>
          <i class="fa-solid fa-bars"></i>
        </button>
        <!-- Logo and Navigation -->
        <div class="logo-and-navigation">
          <nav :class="{ 'nav-open': navOpen }" class="primary-navigation" id="primary-navigation">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/quran">Quran</a></li>
              <li><a href="/about">Over Ons</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </nav>
          <div class="logo">
            <a href="/">
              <img src="/assets/img/logo.png" alt="Logo">
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>



<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ResponsiveHeader',
  setup() {
    const navOpen = ref(false);
    const toggleNav = () => {
      navOpen.value = !navOpen.value;
    };

    return {
      backgroundImageUrl: '/assets/img/color-bg.jpg',
      navOpen,
      toggleNav,
    };
  },
});
</script>

<style scoped>
.header {
  position: relative;
  color: #fff;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.logo-and-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo img {
  /* max-width: 140px; */
  max-height: 80px;
  /* Adjust based on your logo size */
}

.primary-navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.primary-navigation a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.primary-navigation a:hover {
  color: #fbc50b;
  /* Change to match your design */
}

.mobile-nav-toggle {
  display: none;
  background: none;
  border: none;
}

.fa-bars {
  font-size: 24px;
  color: #fff;
}

@media (max-width: 768px) {
  .logo-and-navigation {
    flex-direction: column;
  }

  .primary-navigation {
    display: none;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Semi-transparent background */
  }

  .primary-navigation.nav-open {
    display: flex;
  }

  .mobile-nav-toggle {
    display: block;
  }
}
</style>
