import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toast, { PluginOptions } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// createApp(App).use(router).mount('#app')
// 
const app = createApp(App);

app.use(router);
// app.use(Toast, );

const options: PluginOptions = {
    //position: "top-center",
    timeout: 10000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
  };

app.use(Toast, options);

app.mount('#app');
